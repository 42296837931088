<script>
  import {onMount} from 'svelte';
  import './css/normalize.css';
  // import './css/sets.css'
  import './css/section.css';

  // import Header from './Header.svelte';

  import MTable from './MTable.svelte';
  import MChart from './MChart.svelte';
  import MediumHeader from './MediumHeader.svelte';
  import Header from './wolfiex.header/Header.svelte';
  import MediumRss from './MediumRSS.svelte';

  var data = false;

  async function mount() {
    data = await fetch('./data.json')
      //   .then((e) => {
      // // get the latest date from the file
      //     const myDate = e.headers.get('Last-Modified');
      //     const myNewDate = new Intl.DateTimeFormat('de-AT', {
      //       year: 'numeric',
      //       month: 'numeric',
      //       day: 'numeric',
      //     })
      //       .format(new Date(myDate))
      //       .replace(/\./g, '/');
      //     document.getElementById('lastdate').innerHTML = myNewDate;

      // return e
      //   })
      .then(async (d) => {
        const jsn = await d.json();
        console.log(jsn);
        document.getElementById('lastdate').innerHTML = jsn.date;
        document.getElementById('nreads').innerHTML = jsn.total.toLocaleString(
          undefined,
          {minimumFractionDigits: 0}
        );
        return jsn.data;
      })
      .then(Object.values)

      .then((d) =>
        d.map((e, i) => {
          e.id = i;
          return e;
        })
      );

    console.log(data);

    // the update of the file
    // var myDate = new Date(document.lastModified);

    // document.getElementById('nreads').innerHTML = Math.sum(
    //   data.map((d) => d.views)
    // ).toLocaleString(
    //   undefined, // leave undefined to use the visitor's browser
    //   // locale or a string like 'en-US' to override it.
    //   {minimumFractionDigits: 0}
    // );
  }

  onMount(mount);
</script>

<Header style="dark">
  <!-- <MediumHeader /> -->

</Header>
<main>
  <br /><br />
  <section>
    <div style="width:clamp(400px,60%,800px);margin:auto;">
      <h4>Whence?</h4>
      <br>
      <p class="bx--data-table-header__description">
        In October 2019 I started to write medium articles as a way of
        documenting existing projects, and generating an indexable labbook. This
        has since evolved into an ever-growing collection of software
        engeneering articles benefiting the software community.

        <br /><br />
        The following page contains an RSS feed of the latest articles followed by
        a table of all articles sorted by popularity.
        <br /><br />
        <span id="nreads" />
        reads as of <span id="lastdate" />
      </p>
    </div>
    <br /><br />
  </section>
  <section><MediumRss /></section>

  <br /><br />
  {#if data}
    <section>
      <MTable bind:data />
    </section>
    <section />
    <section><br /><br /></section>
    <section>
      <h4>Publication Timeseries</h4>
      <p class="bx--data-table-header__description">
        Showing the distribution of article types over time.
      </p>
      <MChart bind:data />
    </section>
  {/if}
</main>

<style>
  :global(body) {
    left: 0 !important;
    top: 0;
    width: 100vw !important;
    position: absolute;
    box-sizing: border-box;
    display: inline-block;
    background-color: #e0e0e042!important;
  }
  main {
    text-align: center;
    box-sizing: border-box;
    margin: auto !important;
    min-width: 240px;
    display: block;
  }

  section {
    /* display: block; */
    clear: both;
    overflow: none;
    width: 100%;
    height: 100%;
    /* margin-left: 0; */

    padding-top: 2em;
    /* max-width: 1800px!important; */
    margin: auto !important;
    width:clamp(400px,60%,1400px)
  }
  section::after {
  }
  h1 {
    color: #ff3e00;
    text-transform: uppercase;
    font-size: 4em;
    font-weight: 100;
  }
  /* 
  @media (min-width: 640px) {
    main {
      max-width: none;
    }
  } */

  section {
  }
  section:nth-child(odd) {
    background-color: rgba(255, 255, 255, 0.988) !important;
  }
  section:nth-child(even) {
    background-color: whitesmoke !important;
  }

  #nreads,
  #lastdate {
    font-weight: 500;
  }

  /* :global(section){} */
</style>
