<!-- Place <div> tag where you want the feed to appear -->
    
    <h4 class="bx--data-table-header__title">Current RSS Feed</h4>
    <p class="bx--data-table-header__description">The Latest Publications from <a href="https://daniel-ellis.medium.com/">daniel-ellis.medium.com</a></p>
    <br><br>
    <!-- <div id="curator-feed-testlayout"> -->
      
        <div id="curator-feed-medium-feed-layout">

    </div><!-- The Javascript can be moved to the end of the html page before the </body> tag -->
    
    
    <script type="text/javascript">
        (function(){
        var i,e,d=document,s="script";i=d.createElement("script");i.async=1;i.charset="UTF-8";
        i.src="https://cdn.curator.io/published/bf4c6159-3df7-422b-8f79-7ec7de80af8e.js";
        e=d.getElementsByTagName(s)[0];e.parentNode.insertBefore(i, e);
        })();
        
        </script>




<style>


/* why would I hide the images */
    /* :global(p.medium-feed-image){
        display:none!important;
    } */
    :global(.crt-post-instagram){
        display: none !important;
    }
    /* :global(.crt-social-icon){
        display: none !important;
    } */
    #curator-feed-testlayout{magin:auto!important;}

</style>