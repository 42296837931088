<script>
  export let data = [];
    // import 'carbon-components-svelte/css/g10.css';
  // import 'carbon/charts/styles.css'

  // npm install -D @carbon/charts-svelte d3
  import "carbon-components/css/carbon-components.min.css";
  import "@carbon/charts/styles.min.css";
  
  import {ScatterChart} from '@carbon/charts-svelte';
  import {onMount} from 'svelte';
  import {extent,sum} from 'd3'

  let gdata = false;
  let options = false;

  onMount(() => {


    gdata = data.map((d) => {
      return {
        group: d.topic || 'na',
        date: new Date(d.published).toISOString(),
        value: Math.log10(+d.views),
        views:+d.views,
        title: d.title,
        published: +d.published,
        claps: +d.claps,
      };
    });
    // gdata = odata;
    // console.error(gdata);


    const reads = gdata.map(d=>d.views)
    // console.log(sum(reads),reads)

  options = {
	"title": "",
	"axes": {
		"bottom": {
			"title": null,
			"scaleType": "time",
			"mapsTo": "date",
      "includeZero": false,
      "domain":[new Date(2019, 9).toISOString(),new Date().toISOString()],
      "ticks": {
				"values": [2019,2020,2021,2022,2023,2024].map(y=>new Date(y,0).toISOString()),
        "formatter": (f)=>f.getFullYear(),
			}
		},
		"left": {
			// "scaleType": "log",
      // "includeZero": false,
      "domain":extent(reads).map(Math.log10),
      "mapsTo": "value",
      "ticks": {
        "values":[0,1,2,3,4,5,6,7,8,9,10],
        "formatter": (f)=>'10^'+f,
			}
		}
	},
  // "scatter": {
	// 	"radiusMapsTo": "claps"
	// },
  "data": {
		// "selectedGroups": [...new Set()]
	},
	"experimental": true,
	"zoomBar": {
		"top": {
			"enabled": true,
      "height": "1em",
		},
    
	},
  "height": "250px",
	
};




  });

</script>

{#if Boolean(gdata) & Boolean(options)}
  <ScatterChart data={gdata} {options} class={'mchart'}/>
{/if}

<style>
  .mchart{
    /* width:50%; */
    /* height:300px; */
    /* overflow:hidden; */
    scroll-behavior: unset;
    margin:0;
    padding:0;
    display:inline-block;
  }

  :global(.cds--cc--zoom-bar){height:2em!important;
  filter:hue-rotate(90)!important}
</style>