<script>
  export let data = [];
  import 'carbon-components-svelte/css/white.css';
  import {
    DataTable,
    Pagination,
    Toolbar,
    ToolbarContent,
    ToolbarSearch,
    ToolbarMenu,
    ToolbarMenuItem,
    Button,
  } from 'carbon-components-svelte';
  let page = 1;
  let pageSize = 16;

  let sortKey = 'views';
  let sortDirection = 'descending';

  data = data.sort((a,b) => b.views - a.views);
  $: console.log('sortKey', sortKey);
  $: console.log('sortDirection', sortDirection);
  // console.warn(data);
</script>

<DataTable
  sortable
  zebra
  sortKey={sortKey}
  sortDirection={sortDirection}
  title="Top Articles"
  description="Browse, sort by columns or search published articles."
  headers={[
    {key: 'title', value: 'Name', sort: false},
    {key: 'topic', value: 'Classification'},
    {key: 'views', value: 'Reads'},
    //   { key: "cost", value: "Cost", display: (cost) => cost + " €" },
    {
      key: 'published',
      value: 'Published',
      display: (date) => new Date(date).toLocaleString().split(' ')[0],
      // sort: (a, b) => new Date(a) - new Date(b),
    },
  ]}
  bind:pageSize
  bind:page
  rows={data}
  on:click:row={(d) => {

    window.open(d.detail.url, '_blank');
    console.log(d.detail);
  }}
>
  <Toolbar>
    <ToolbarContent>
      <ToolbarSearch persistent value="" shouldFilterRows />
      <ToolbarMenu>
        <ToolbarMenuItem
          primaryFocus
          kind="tertiary"
          disabled={sortKey === 'views' && sortDirection === 'descending'}
          on:click={() => {
            sortKey = 'views';
            sortDirection = 'descending';
          }}>Sort: Popular</ToolbarMenuItem
        >
        <ToolbarMenuItem
          hasDivider
          warn
          kind="tertiary"
          disabled={sortKey === 'published' && sortDirection === 'descending'}
          on:click={() => {
            sortKey = 'published';
            sortDirection = 'descending';
          }}>Sort: Latest</ToolbarMenuItem
        >
        <ToolbarMenuItem href="https://daniel-ellis.medium.com/">
          Follow / Subscribe
        </ToolbarMenuItem>
      </ToolbarMenu>
    </ToolbarContent>
  </Toolbar>
</DataTable>
<Pagination
  bind:pageSize
  bind:page
  totalItems={data.length}
  pageSizeInputDisabled
/>

<style>

  :global(tbody tr){
    height:2.8em!important;
  }
</style>